import React, { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal, useAccount, useIsAuthenticated } from '@azure/msal-react';
import { v5 as uuidv5 } from 'uuid';

import TenantSelector from 'containers/auth';
import AuthPage from 'containers/auth/components/AuthPage';
import CircularProgressComponent from 'components/progressIndicators/CircularProgressComponent';
import { useAppSelector, useAppDispatch } from 'store/index';
import {
  selectedTenant,
  setAuthenticated,
  setToken,
  setTokenParsed,
  setSessionId,
} from 'store/auth';
import App from './App';
import ErrorPage from 'components/ErrorPage';
import { useSsoSyncMutation } from 'services/login';

const NAMESPACE = uuidv5.URL;

const generateSessionId = (sub: string, iat: number) => {
  if (sub && iat) {
    const stringToHash = `${sub}-${iat}`;
    return uuidv5(stringToHash, NAMESPACE);
  }
  return '';
};

export default function AuthenticationChecker() {
  const dispatch = useAppDispatch();
  const { instance, accounts, inProgress } = useMsal();
  const isMsalAuthenticated = useIsAuthenticated();
  const [error, setError] = useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const [tenantIdFromUrl, setTenantIdFromUrl] = useState<string | null>(null);
  const [patientIdFromUrl, setPatientIdFromUrl] = useState<string | null>(null);
  const [idTokenHintFromUrl, setIdTokenHintFromUrl] = useState<string | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [ssoSync] = useSsoSyncMutation();
  const [magicLoginInProgress, setMagicLoginInProgress] = useState(false);

  const account = useAccount(accounts[0] || {});
  const tenant = useAppSelector(selectedTenant);

  const handleSignIn = async () => {
    setIsConnecting(true);
    await login({ tenantIdFromUrl, idTokenHintFromUrl });
  };

  const login = useCallback(
    async ({
      tenantIdFromUrl,
      idTokenHintFromUrl,
    }: {
      idTokenHintFromUrl: string | null;
      tenantIdFromUrl: string | null;
    }) => {
      await instance.loginRedirect({
        scopes: ['openid'],
        ...(idTokenHintFromUrl && {
          extraQueryParameters: {
            id_token_hint: idTokenHintFromUrl,
          },
        }),
        state: tenantIdFromUrl ? JSON.stringify({ tenant_id: tenantIdFromUrl }) : undefined,
      });
    },
    [instance]
  );

  // Handle successful login and token extraction
  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          account: account,
          scopes: ['openid'],
          ...(idTokenHintFromUrl && {
            extraQueryParameters: {
              id_token_hint: idTokenHintFromUrl,
            },
          }),
        })
        .then(async (response) => {
          const idToken = response.idToken;
          const idTokenClaims = response.idTokenClaims;
          const { sub, iat, extension_Roles } = idTokenClaims as {
            sub: string;
            iat: number;
            extension_Roles?: string;
          };
          const sessionId = generateSessionId(sub, iat);

          if (idToken && idTokenClaims) {
            if (!extension_Roles) {
              try {
                await ssoSync({ token: idToken }).unwrap();
                setIsAccountCreated(true);
              } catch (error) {
                setError(true);
                console.error(error);
              }
              return;
            } else if (
              extension_Roles &&
              (extension_Roles?.includes('system_admin') || extension_Roles?.includes('aristotle'))
            ) {
              setError(true);
            } else {
              setIsAuthenticated(true);
              dispatch(setAuthenticated(true));
              dispatch(setToken(idToken));
              dispatch(setTokenParsed(idTokenClaims));
              dispatch(setSessionId(sessionId));
            }
          }
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              scopes: ['openid'],
              ...(idTokenHintFromUrl && {
                extraQueryParameters: {
                  id_token_hint: idTokenHintFromUrl,
                },
              }),
              state: tenantIdFromUrl ? JSON.stringify({ tenant_id: tenantIdFromUrl }) : undefined,
            });
          } else {
            console.error(error);
          }
        })
        .finally(() => {
          setIsConnecting(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, instance, dispatch]);

  // Handle magic tenant id, patient id and token hint from URL
  useEffect(() => {
    const handleRedirectResponse = async () => {
      const idTokenHint = localStorage.getItem('id_token_hint');
      const patientId = localStorage.getItem('patient_id');
      if (idTokenHint) {
        setIdTokenHintFromUrl(idTokenHint);
      }
      if (patientId) {
        setPatientIdFromUrl(patientId);
      }
      try {
        const response = await instance.handleRedirectPromise();
        if (response && response.state) {
          const parsedState = JSON.parse(response.state);
          const tenantId = parsedState.tenant_id;
          if (tenantId) {
            setTenantIdFromUrl(tenantId);
          }
        }
      } catch (error) {
        console.error('Error handling redirect:', error);
      }
    };

    handleRedirectResponse();
  }, [instance]);

  // Handle redirection if patient_id is in magic link
  useEffect(() => {
    if (isAuthenticated && tenant && patientIdFromUrl && typeof window !== 'undefined') {
      if (localStorage.getItem('patient_id')) {
        localStorage.removeItem('patient_id');
      }
      window.location.assign(`/patient/${patientIdFromUrl}`);
    }
  }, [isAuthenticated, tenant, patientIdFromUrl]);

  useEffect(() => {
    async function magicLinkLogin({
      tenantId,
      idTokenHint,
    }: {
      tenantId: string;
      idTokenHint: string;
    }) {
      setMagicLoginInProgress(true);
      await login({ tenantIdFromUrl: tenantId, idTokenHintFromUrl: idTokenHint });
      setMagicLoginInProgress(false);
    }

    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      const tenantId = params.get('tenant_id');
      const idTokenHint = params.get('id_token_hint');
      const patientId = params.get('patient_id');

      if (tenantId) {
        setTenantIdFromUrl(tenantId);
      }
      if (idTokenHint) {
        setIdTokenHintFromUrl(idTokenHint);
      }
      if (patientId) {
        setPatientIdFromUrl(patientId);
      }

      if (tenantId && idTokenHint) {
        magicLinkLogin({ tenantId, idTokenHint });
      }
    }
  }, [login]);

  if (isAccountCreated) {
    return (
      <ErrorPage
        title="You’re account has been created"
        description="Please click on sign in."
        isSignInError
      />
    );
  }

  if (error) {
    return (
      <ErrorPage
        title="We're sorry, but you don't have permissions to access this platform."
        description="Please contact your system administrator to resolve this issue or try sign in again."
        isSignInError
      />
    );
  }

  // Show Loader when the app is starting up or handling a redirect or when token is getting acquired
  if (
    inProgress === 'startup' ||
    inProgress === 'handleRedirect' ||
    (!isAuthenticated && isMsalAuthenticated)
  ) {
    return <CircularProgressComponent />;
  }

  // Show the AuthPage if the user is not authenticated
  if (!isAuthenticated) {
    return <AuthPage isConnecting={isConnecting || magicLoginInProgress} onSignIn={handleSignIn} />;
  }

  // Once authenticated, show the TenantSelector if the tenant is not selected
  if (isAuthenticated && (!tenant || isEmpty(tenant?.roles))) {
    return <TenantSelector tenantId={tenantIdFromUrl} />;
  }

  // When the tenant is selected, show the main App
  if (isAuthenticated && tenant) {
    return <App />;
  }

  return <CircularProgressComponent />;
}
